<template>
  <div class="list" style="max-width: 100%">
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
            label="Sort"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
        >
          <b-input-group size="sm">
            <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>

            <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="search"
                type="search"
                placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!search" @click="search = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
            v-model="sortDirection"
            label="Filter On"
            description="Leave all unchecked to filter on all data"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
          >
            <b-form-checkbox value="user">Customer</b-form-checkbox>
            <b-form-checkbox value="setTopBoxType">Box-Type</b-form-checkbox>
            <b-form-checkbox value="macAddress">MAC-Address</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group
            label="Show Columns"
            description="Check all to show every column"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
              v-model="selectedFields"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
          >
            <b-form-checkbox checked="user" value="user">Customer</b-form-checkbox>
            <b-form-checkbox checked="PvrNumber" value="PvrNumber">PVR Ticket</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
            label="From"
            label-for="startDate-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
                id="startDate-input"
                v-model="startDate"
                type="date"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!startDate" @click="startDate = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
            label="To"
            label-for="endDate-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
                id="endDate-input"
                v-model="endDate"
                type="date"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!endDate" @click="endDate = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
        >
          <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
      </b-col>
      <b-col sm="4" md="4" class="my-1">
        <b-button @click="createPDF()" variant="success">Create PDF</b-button>
      </b-col>
      <b-col sm="4" md="4" class="my-1">
        <download-excel
            class="btn btn-default"
            :data="csvItem"
            :fields="csvFields"
            worksheet="My Worksheet"
            name="filename.xls"
            :before-generate="createCSV"
        >
          <b-button variant="success">Create XLS-File</b-button>
        </download-excel>
      </b-col>
    </b-row>

    <b-table class="col-12" striped hover
             :items="transactions"
             :fields="fields"
             :current-page="currentPage"
             :per-page="perPage"
             :filter="filter"
             :filter-included-fields="filterOn"
             :filter-function="filterTable"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :sort-direction="sortDirection"
             stacked="md"
             show-empty
             small
             @filtered="onFiltered">
      <template #cell(#)="row">
        {{ row.index }}
      </template>
      <template #cell(user.EMail)="row">
        <b-button size="sm" @click="row.toggleDetails">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} {{ row.item.user.EMail }}
        </b-button>
      </template>
      <template #cell(createdAt)="row">
        {{ row.item.createdAt | moment('DD.MM.YYYY - HH:mm') }}
      </template>
      <template #row-details="row">
        <b-card>
          <ul>
            <li>Name: {{ row.item.user.name }}</li>
            <li>Street: {{ row.item.user.street }}</li>
            <li>ZIP: {{ row.item.user.zip }}</li>
            <li>City: {{ row.item.user.city }}</li>
            <li>Country: {{ row.item.Country }}</li>
            <li>Newsletter:{{ row.item.user.newsletter }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
import TransactionDataService from "../services/TransactionDataService";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {mapState} from "vuex";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "setTopBoxTypes-list",
  data() {
    return {
      name: "",
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: this.totalRows, text: "All"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      search: null,
      startDate: null,
      endDate: null,
      filterOn: [],
      filteredItems: [],
      csvItem: [],
      pdfFields: [
        '#',
        'Customer',
        'Box Type',
        'MAC Address',
        'PVR Ticket',
        'Purchased At',
      ],
      csvFields: {
        Id: 'Id',
        'E-Mail': 'customer',
        Name: 'name',
        Street: 'street',
        ZIP: 'zip',
        City: 'city',
        Country: 'country',
        Newsletter: 'newsletter',
        'Mac Address': 'macAddress',
        'Box Type': 'boxType',
        'PVR Ticket': 'pvrTicket',
        'Purchased At': 'purchasedAt',
      },
      selectedFields: ["user", "PvrNumber"],
      checkedPvrNumber: "PvrNumber",
      checkedUser: 'user',
    };
  }
  ,
  computed:  {

    ...mapState([
        'transactions',
        'totalRows'
    ]),

    fields: function(){
      console.log("trigger fields");
      let fields = [];
      fields.push('#');
      if(this.selectedFields[0] === 'user' || this.selectedFields[1] === 'user'){
        fields.push( {
          key: 'user.EMail',
          label: 'Customer',
          sortable: true
        });
      }
      fields.push({
        key: 'setTopBoxType.Name',
        sortable: true,
        label: 'Box Type'
      },
      {
        key: 'MacAddress',
        sortable: true,
        label: 'MAC Address'
      });
      if(this.selectedFields[0] === 'PvrNumber' || this.selectedFields[1] === 'PvrNumber'){
        fields.push( {
          key: 'PvrNumber',
          label: 'PVR Ticket',
          sortable: true
        })
      }
      fields.push( {
        key: 'createdAt',
        sortable: true,
        label: 'Purchased At'
      });
      console.log('Das sind die fields');
      console.log(fields);
      return fields;
    },

    filter: function() {
      if ((this.search === null || this.search === '')){
        if(this.startDate === null || this.startDate === ''){
          if(this.endDate === null || this.startDate === ''){
            return null;
          }else {
            return this.endDate;
          }
        } else{
          return this.startDate
        }
      } else {
        return this.search;
      }
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            console.log('SORT OPTIONS');
            console.log(f);
            console.log(f.key);
            if(f.key === 'PurchasedAt'){
              return {text: 'Purchased At', value: 'createdAt'}
            }
            return {text: f.label, value: f.key}
          })
    },
  },

  methods: {
    retrieveTransactions() {
      TransactionDataService.getAll()
          .then(response => {
            this.transactions = response.data;
            console.log(response.data);
            this.totalRows = this.transactions.length
            this.filteredItems = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },

    filterTable(row, filter) {
      console.log('TRIGGER FILTER');
      console.log(this.currentPage);
      let startDate = this.startDate;
      let endDate = this.endDate;
      let startDateValid = this.$moment(startDate, "YYYY-MM-DD", true).isValid();
      let endDateValid = this.$moment(endDate, "YYYY-MM-DD", true).isValid()
      let rowDate = new Date(row.createdAt);
      rowDate.setHours(0, 0, 0, 0);
      console.log('STARTDATE: ' + startDate);
      console.log(startDateValid);
      if (startDateValid && !endDateValid) {
        let date = new Date(startDate);
        console.log(date.getDate() + '.' + date.getMonth());
        date.setHours(0, 0, 0, 0);
        if (rowDate < date) {
          return false;
        }
      } else if (startDateValid && endDateValid) {
        let date = new Date(startDate);
        console.log(date.getDate() + '.' + date.getMonth());
        let date2 = new Date(endDate);
        date.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        if (rowDate < date || rowDate > date2) {
          return false;
        }
      } else if (!startDateValid && endDateValid) {
        let date = new Date(endDate);
        date.setHours(0, 0, 0, 0);
        if (rowDate > date) {
          return false;
        }
      }
      if(this.startDate === filter || this.endDate === filter){
        return true;
      }
      let f = filter;
      if(f != null && f != ''){
        f = filter.replaceAll('*', '.');
      } else {
        return true;
      }
      let reg = new RegExp(f.toUpperCase());
      console.log(reg);
      if (this.filterOn.length > 0) {
        for (let i = 0; i < this.filterOn.length; i++) {
          console.log("DAS IST DIE ROW");
          console.log(row);
          if (this.filterOn[i] == 'user') {
            if (reg.test(row.user.EMail.toUpperCase())) {
              return true;
            }
          }
          if (this.filterOn[i] == 'setTopBoxType') {
            if (reg.test(row.setTopBoxType.Name.toUpperCase())) {
              return true;
            }
          }
          if (this.filterOn[i] == 'macAddress') {
            if (reg.test(row.macAddress.toUpperCase())) {
              return true;
            }
          }
          if (this.filterOn[i] == 'createdAt') {
            if (reg.test(row.createdAt.toUpperCase())) {
              return true;
            }
          }
        }
      } else {
        console.log("DAS IST DIE ROW");
        console.log(row);
        if(typeof row !== undefined){
          if (reg.test(row.user.EMail.toUpperCase())) {
            return true;
          }
          if (reg.test(row.setTopBoxType.Name.toUpperCase())) {
            return true;
          }
          if (reg.test(row.MacAddress.toUpperCase())) {
            return true;
          }
          if (reg.test(row.createdAt.toUpperCase())) {
            return true;
          }
        }
      }
      return false;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      console.log("FILTERED ITEMS YUHU");
      console.log(filteredItems);
      this.$store.commit('SET_TOTAL_ROWS', {totalRows: filteredItems.length});
      if(this.$store.state.totalRows > this.perPage){
        let div = this.$store.state.totalRows / this.perPage ;
        console.log('DIV: ' + div + ' ' + Math.ceil(div));
        if(this.currentPage > Math.ceil(div)){
          this.currentPage = 1;
        }
      }
      this.filteredItems = filteredItems;
    }
    ,

    refreshList() {
      this.retrieveTransactions();
    },

    createCSV(){
      this.csvItem = [];
      if(this.filteredItems.length <= 0){
        this.filteredItems = this.$store.state.transactions;
      }
      //this.csvItem = this.filteredItems;
      console.log(this.filteredItems);
      for(let i = 0; i < this.filteredItems.length; i++){
        this.csvItem.push({
          'Id': this.filteredItems[i].Id,
          'customer': this.filteredItems[i].user.EMail,
          'name': this.filteredItems[i].user.name,
          'street': this.filteredItems[i].user.street,
          'zip': this.filteredItems[i].user.zip,
          'city': this.filteredItems[i].user.city,
          'country': this.filteredItems[i].Country,
          'newsletter': this.filteredItems[i].user.newsletter,
          'boxType': this.filteredItems[i].setTopBoxType.Name,
          'macAddress': this.filteredItems[i].MacAddress,
          'pvrTicket': this.filteredItems[i].PvrNumber,
          'purchasedAt': this.$moment(this.filteredItems[i].createdAt).format('DD.MM.YYYY'),
        });
      }
      console.log(this.csvItem);
    },

    createPDF() {
      console.log(this.$store.state.transactions);
      console.log(this.$store.state.totalRows);
      console.log('filterOn');
      console.log(this.filterOn);
      console.log('FILTEREDITEMS!');
      console.log(this.filteredItems);
      let tableItems = [];
      console.log(this.sortDirection);
      console.log(this.sortDesc);
      let widths = [];
      let pdfFields = [
        '#',
      ];
      widths.push('auto');
      if(this.selectedFields[0] === 'user' || this.selectedFields[1] === 'user'){
        widths.push(200);
        pdfFields.push('Customer');
      }
      widths.push('auto', 'auto');
      pdfFields.push('Box Type',  'MAC Address');
      if(this.selectedFields[0] === 'PvrNumber' || this.selectedFields[1] === 'PvrNumber'){
        widths.push('auto');
        pdfFields.push( 'PVR Ticket');
      }
      pdfFields.push( 'Purchased At');
      widths.push(100);
      let filterOnText = '';
      let filterOnBoxType = false;
      for(let i = 0; i < this.filterOn.length; i++){
        if(this.filterOn[i] == 'user'){
          filterOnText = filterOnText + 'Customer; ';
        } else if(this.filterOn[i] == 'setTopBoxType'){
          filterOnText = filterOnText + 'Box Type; ';
          filterOnBoxType = true;
          widths = ['auto', 100, 'auto', 'auto', 100]
        } else if(this.filterOn[i] == 'macAddress'){
          filterOnText = filterOnText + 'MAC Address; ';
        }
      }/*
      if(this.filterOn.length === 1){
        if(this.filterOn[0] === 'setTopBoxType'){
          filterOnText = 'Box Type; ';
          filterOnBoxType = true;
        }
      }*/
      if(this.filteredItems.length <= 0 && this.filter === null){
        this.filteredItems = this.$store.state.transactions;
      }
      if(this.filterOn.length === 0){
        filterOnText = '';
      }
      if (this.sortBy == 'setTopBoxType.Name') {
        if (!this.sortDesc) {
          this.filteredItems.sort((a, b) => a.setTopBoxType.Name.toLowerCase()
              .localeCompare(b.setTopBoxType.Name.toLowerCase()));
        } else {
          this.filteredItems.sort((a, b) => (a.setTopBoxType.Name.toLowerCase() < b.setTopBoxType.Name.toLowerCase())
              ? 1 : ((b.setTopBoxType.Name.toLowerCase() < a.setTopBoxType.Name.toLowerCase()) ? -1 : 0));
        }
      } else if (this.sortBy == 'user.EMail') {
        if (!this.sortDesc) {
          this.filteredItems.sort((a, b) => a.user.EMail.toLowerCase()
              .localeCompare(b.user.EMail.toLowerCase()));
        } else {
          this.filteredItems.sort((a, b) => a.user.EMail.toLowerCase()
              .localeCompare(b.user.EMail.toLowerCase()));
          this.filteredItems = this.filteredItems.reverse();
        }
      } else if (this.sortBy == 'macAddress') {
        if (!this.sortDesc) {
          this.filteredItems.sort((a, b) => a.MacAddress.toLowerCase()
              .localeCompare(b.MacAddress.toLowerCase()));
        } else {
          this.filteredItems.sort((a, b) => (a.MacAddress.toLowerCase() < b.MacAddress.toLowerCase())
              ? 1 : ((b.MacAddress.toLowerCase() < a.MacAddress.toLowerCase()) ? -1 : 0));
        }
      } else if (this.sortBy == 'pvrNumber') {
        if (!this.sortDesc) {
          this.filteredItems.sort((a, b) => a.PvrNumber.toLowerCase()
              .localeCompare(b.PvrNumber.toLowerCase()));
        } else {
          this.filteredItems.sort((a, b) => (a.PvrNumber.toLowerCase() < b.PvrNumber.toLowerCase())
              ? 1 : ((b.PvrNumber.toLowerCase() < a.PvrNumber.toLowerCase()) ? -1 : 0));
        }
      } else if (this.sortBy == 'createdAt') {
        if (!this.sortDesc) {
          this.filteredItems.sort((a, b) => a.createdAt.toLowerCase()
              .localeCompare(b.createdAt.toLowerCase()));
        } else {
          this.filteredItems.sort((a, b) => (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
              ? 1 : ((b.createdAt.toLowerCase() < a.createdAt.toLowerCase()) ? -1 : 0));
        }
      }
      let fText = '';
      if(this.search !== null){
        fText = this.search
      }
      console.log('FILTER ON TEXT: ' + filterOnText);
      let filterText = '';
      if(filterOnText !== ''){
        filterText += 'Filter: ' + filterOnText + ' ';
      }
      if(fText !== ''){
        filterText += 'search: ' + fText;
      }
      console.log(this.startDate);
      console.log(this.endDate);
      let startDate = '';
      let endDate = '';
      let dateText1 = '';
      let dateText2 = '';
      if(this.startDate !== null && this.startDate !== ''){
        startDate = this.$moment(this.startDate).format('DD.MM.YYYY');
        dateText1 = 'From: ' + startDate + '          ';
      }
      if(this.endDate !== null && this.endDate !== ''){
        endDate = this.$moment(this.endDate).format('DD.MM.YYYY');
        dateText2 = 'To: ' + endDate;
      }
      var docDefinition = {
        header: {text: 'Transactions', style: 'header',},
        pageOrientation: 'landscape',
        content: [
          { text: filterText, fontSize: 15, marginBottom: 5, color: 'black'},
          { text: dateText1 + dateText2, fontSize: 15, marginBottom: 5, color: 'black'},
          {
            table: {
              headerRows: 1,
              widths: widths,
              body: []
            }
          }
        ],
        styles: {
          header: {
            fontSize: 22,
            bold: true,
            marginLeft: 20,
          },
        }
      };
      docDefinition.content[2].table.body.push(pdfFields);
      for (var i = 0; i < this.filteredItems.length; i++) {
        if(filterOnBoxType){
          let tableString = '{"#": "' + (i + 1) + '",';
          if(this.selectedFields[0] === 'user' || this.selectedFields[1] === 'user'){
            tableString += '"Customer": " E-Mail: ' + this.filteredItems[i].user.EMail + "\\n" +
                "Name: " +  this.filteredItems[i].user.name + "\\n" +
                'Street: ' + this.filteredItems[i].user.street + "\\n" +
                'ZIP: ' + this.filteredItems[i].user.zip + "\\n" +
                'City: ' + this.filteredItems[i].user.city + "\\n" +
                'Country: ' + this.filteredItems[i].Country + "\\n" +
                'Newsletter: ' + this.filteredItems[i].user.newsletter + '\\n",'
          }
          tableString += '"Box Type": "' + this.filteredItems[i].setTopBoxType.Name + '",';
          tableString += '"MAC-Address": "' + this.filteredItems[i].MacAddress + '",';
          if(this.selectedFields[0] === 'PvrNumber' || this.selectedFields[1] === 'PvrNumber'){
            tableString += '"PVR-Ticket": "' + this.filteredItems[i].PvrNumber + '",';
          }
          tableString += '"Purchased At": "' + this.$moment(this.filteredItems[i].createdAt).format('DD.MM.YYYY') + '"}';
          console.log(tableString);
          let tableJson = JSON.parse(tableString);
          tableItems.push(tableJson);
        } else {
          let tableString = '{"#": "' + (i + 1) + '",';
          if(this.selectedFields[0] === 'user' || this.selectedFields[1] === 'user'){
            tableString += '"Customer": " E-Mail: ' + this.filteredItems[i].user.EMail + "\\n" +
                "Name: " +  this.filteredItems[i].user.name + "\\n" +
                'Street: ' + this.filteredItems[i].user.street + "\\n" +
                'ZIP: ' + this.filteredItems[i].user.zip + "\\n" +
                'City: ' + this.filteredItems[i].user.city + "\\n" +
                'Country: ' + this.filteredItems[i].Country + "\\n" +
                'Newsletter: ' + this.filteredItems[i].user.newsletter + '\\n",'
          }
          tableString += '"Box Type": "' + this.filteredItems[i].setTopBoxType.Name + '",';
          tableString += '"MAC-Address": "' + this.filteredItems[i].MacAddress + '",';
          if(this.selectedFields[0] === 'PvrNumber' || this.selectedFields[1] === 'PvrNumber'){
            tableString += '"PVR-Ticket": "' + this.filteredItems[i].PvrNumber + '",';
          }
          tableString += '"Purchased At": "' + this.$moment(this.filteredItems[i].createdAt).format('DD.MM.YYYY') + '"}';
          console.log(tableString);
          let tableJson = JSON.parse(tableString);
          tableItems.push(tableJson);
        }
        docDefinition.content[2].table.body.push(Object.values(tableItems[i]));
      }
      console.log('THIS SORTBY: ' + this.sortBy);
      pdfMake.createPdf(docDefinition).download('PO.pdf');
    },

  }
  ,

  mounted() {
    //this.retrieveTransactions();
    console.log("MOUNTED BITCH!");
    this.$store.dispatch("GET_ALL_TRANSACTIONS").then(() => {
      this.filteredItems = this.$store.state.transactions;
      console.log(this.filteredItems);
    });


  }
}
;
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>
